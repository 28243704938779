import React, { useEffect, useState } from 'react';
import './App.css';
import logo from './logo.svg';  // Assurez-vous que le logo est dans le dossier public

function App() {
  const [isMobile, setIsMobile] = useState(true);

  useEffect(() => {
    const userAgent = navigator.userAgent || navigator.vendor || window.opera;
    const mobileCheck = /android|webos|iphone|ipad|ipod|blackberry|iemobile|opera mini/i.test(userAgent);
    setIsMobile(mobileCheck);

    // Disable scroll
    document.body.style.overflow = 'hidden';
    
    // Re-enable scroll on cleanup
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  if (!isMobile) {
    return (
      <div className="h-screen flex items-center justify-center bg-neon-white">
        <h1 className="text-neon-red text-3xl font-gliker">Ce site est uniquement accessible depuis un téléphone mobile.</h1>
      </div>
    );
  }

  return (
    <div className="h-screen flex flex-col items-center justify-center bg-neon-white relative">
      <img src={logo} alt="Logo" className="fixed top-4 w-48 h-48 rounded-full neon-logo"/>
      <div className="w-full h-2/4 px-4 mt-48 flex flex-col items-center justify-center space-y-4">
        <a href="https://commande.captain-chicken.com/?tbl=1&s=34vaimieod" className="neon-button neon-green w-full h-2/4 flex items-center justify-center text-2xl mb-4 flash1">Sur place</a>
        <a href="https://commande.captain-chicken.com/?frl=retrait" className="neon-button neon-yellow w-full h-2/4 flex items-center justify-center text-2xl flash2">à emporter</a>
      </div>
    </div>
  );
}

export default App;
